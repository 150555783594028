<template>
  <div>
    <!-- How it works + Icon Cards -->
    <section>
      <div class="container text-center">
        <h2 class="text-2xl font-bold md:text-4xl">How it works</h2>
      </div>
      <div
        class="container py-10 flex flex-col justify-center items-center md:gap-6 md:items-start md:flex-row"
      >
        <FrontendIconCard
          v-for="(card, i) in homepageIconCards"
          :key="i"
          :title="card.title"
          :description="card.description"
          :icon="card.icon"
        />
      </div>
      <div class="container text-center font-poppins font-light">
        <p>
          Have questions?
          <NuxtLink to="/faqs" class="font-medium underline text-blue-500"
            >View our Frequently Asked Questions here</NuxtLink
          >
        </p>
      </div>
    </section>

    <!-- Call-out Card -->
    <section>
      <div class="py-10 md:container md:pt-[5.75rem] md:pb-[4.375rem]">
        <FrontendCalloutCard
          text="Since 2017, we have helped almost 1 million people find support for their mental health"
        />
      </div>
    </section>

    <!-- Quote Carousel -->
    <div ref="quoteScrollRef">
      <LazyFrontendQuoteScroll
        v-if="quoteScrollShowing"
        :quotes="quotes"
        :initial-quote="initialQuoteIndex"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
// only load carousel when it's in view
const quoteScrollRef = ref<HTMLElement | null>(null)
const quoteScrollShowing = ref(false)
useIntersectionObserver(
  quoteScrollRef,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      quoteScrollShowing.value = true
    }
  },
  { rootMargin: '200px' }
)

const homepageIconCards = [
  {
    title: 'Find services',
    description:
      'Search a directory of tens of thousands mental health support services across the UK. We are here to help you find relevant support available in your local area or nationally.',
    icon: 'heroicons:magnifying-glass'
  },
  {
    title: 'Make it relevant to you',
    description:
      'Discovering the support that is right for you is easy with the Hub of Hope. Use our filters to find services that best meet your unique needs.',
    icon: 'heroicons:eye'
  },
  {
    title: 'Get help easily',
    description:
      'We provide detailed information on mental health services to help you choose the support that is right for you. We also share guidance on how to get in touch with services and access help.',
    icon: 'heroicons:sparkles'
  }
]

const { width } = useWindowSize()
const filler = () => ({ quote: '', author: '', filler: true })
const quotes = computed(() => {
  const data = [
    {
      quote:
        "I wish I'd known about the Hub of Hope sooner. It could have been a lifeline for myself and others during difficult times.",
      author: 'Jon, from London'
    },
    {
      quote: "Tell someone you're suffering, there is help in places and people you don't yet know.",
      author: 'Anonymous story'
    },
    {
      quote:
        'This will pass, this journey will not end here, it will influence the next part so much richer as you will have grown because of who you were today.',
      author: 'Jen, from Jersey'
    },
    {
      quote:
        'It helped me to find my friend support after they were struggling with depression. We found two local places which in all honesty saved his life.',
      author: 'Martin, from Liverpool'
    },
    { quote: 'Our mental health is far more important than work.', author: 'Anonymous story' },
    {
      quote:
        'A woman had a panic attack and I was able to show her all the different anxiety support that was in her neighbourhood.',
      author: 'Dave, from the Midlands'
    },
    {
      quote:
        "It helped a friend of mine during a difficult period by connecting them to local services they didn't even know existed.",
      author: 'Jon, from London'
    },
    {
      quote:
        'I wish I knew this existed when I have had my own struggles. I desperately needed it when I was turned away from my GP.',
      author: 'Anonymous story'
    },
    { quote: 'I found somewhere that could give me so much support.', author: 'Ellie, from Cheshire' },
    {
      quote:
        'Moments of hope came when I made my first appointment to start getting help, putting that idea in to action was a huge relief and turning point',
      author: 'Catherine, from Newcastle'
    },
    {
      quote:
        "Hold On Pain Ends (HOPE). What you're experiencing now is temporary and you will come through this.",
      author: 'Jo, from Ipswich, Suffolk'
    }
  ]
  // we need 2 fillers down to screen width 1024px then 1 filler down to 768px then none after that
  // these are the default breakpoints for tailwindcss
  if (width.value >= 1024) {
    data.push(filler(), filler())
    data.unshift(filler(), filler())
  } else if (width.value >= 768) {
    data.push(filler())
    data.unshift(filler())
  }
  return data
})

const initialQuoteIndex = computed(() => {
  // we have 2 fillers in width >= 1024px and 1 filler in width >= 768px
  // we just want to start in a place where there's no whitespace
  return width.value >= 1024 ? 3 : width.value >= 768 ? 2 : 1
})
</script>
